import { useCallback, useEffect, useState } from "react"
import { BareFetcher } from "swr"
import useSWRInfinite from "swr/infinite"

export function usePagination<
  R extends {
    nextPageToken?: string
    totalCount: number
  }
>(
  baseUrl: string | null,
  pageSize: number,
  autoLoad: boolean,
  queryFilters: string[] = [],
  queryParameters: { [key: string]: string } = {},
  fetcher?: BareFetcher<R>
) {
  // TODO: useCallback을 사용하는 것은 현재 시점에서는 의미가 없으나,
  // 이후에 dependency를 관리하게 되면 필요해질 것.
  const getSWRKey = useCallback(
    (pageIndex: number, previousPageData: R | null) => {
      // Key is null
      if (baseUrl === null) return null

      // Reached the end
      if (previousPageData && !previousPageData.nextPageToken) return null
      const token = previousPageData?.nextPageToken
      const queryParams = new URLSearchParams({
        ...(queryFilters && { filter: queryFilters!.join(";") }),
        page_size: pageSize.toString(),
      })
      if (token) {
        queryParams.append("page_token", token)
      }
      if (queryParameters) {
        Object.keys(queryParameters).forEach((key) => {
          queryParams.append(key, queryParameters[key])
        })
      }
      return `${baseUrl}?${queryParams}`
    },
    [queryFilters, baseUrl === null]
  )
  const { data, mutate, size, setSize } = useSWRInfinite<R>(
    getSWRKey,
    fetcher ?? null
  )
  const [isLoading, setLoading] = useState(true)
  const firstPage = data?.[0]
  useEffect(() => {
    if (autoLoad && firstPage) {
      const totalSize = Math.ceil(firstPage.totalCount / pageSize)
      if (totalSize <= 1) {
        setLoading(false)
      }
      setSize(totalSize)
    }
  }, [firstPage, setSize])
  useEffect(() => {
    if (autoLoad && size > 1 && data?.[size - 1]) {
      setLoading(false)
    }
  }, [size, data, !!baseUrl])
  return { data, mutate, size, setSize, isLoading }
}
