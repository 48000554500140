import { Badge } from "baseui/badge"
import { Button } from "baseui/button"
import { DatePicker } from "baseui/datepicker"
import ko from "date-fns/locale/ko"
import dayjs from "dayjs"
import { useRouter } from "next/router"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { TemporaryOrderTable } from "./TemporaryOrderTable"
import { ListTemporaryOrdersResponse } from "@today/api/taker"
import { Checkbox } from "baseui/checkbox"
import { AddressCleaningModal } from "./AddressCleaningModal"
import { TemporaryOrderDetailDrawer } from "./TemporaryOrderDetailDrawer"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "baseui/modal"
import { FormControl } from "baseui/form-control"
import CryingPepe from "../../assets/pepe.png"

import { Textarea } from "baseui/textarea"
import axios from "axios"
import { toaster } from "baseui/toast"
import { usePagination } from "@today/lib/hooks"
import { sleepAsync } from "@today/lib"
import { Input } from "baseui/input"
import useSWR from "swr"
import { FilterItem } from "../FilterItem"
import { Value } from "baseui/select"

const PAGE_SIZE = 100

const targetClientIdValues: { id: string; label: string }[] = [
  { id: "110201815933", label: "CJ대한통운" },
  { id: "108472190524", label: "롯데홈쇼핑" },
  { id: "122741676407", label: "롯데택배" },
  { id: "164880727013", label: "한진" },
  { id: "155570418612", label: "우진" },
  { id: "173222447645", label: "stclogis" },
]

export const TARGET_CLIENT_IDS = targetClientIdValues.map(({ id }) => id)

export function TemporaryOrdersPage() {
  const router = useRouter()
  const formatDate = (date: Date) => dayjs(date).format("YYYY-MM-DD")
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [startDateStr, endDateStr] = [
    formatDate(startDate),
    formatDate(endDate),
  ]
  const [isUnfixed, setUnfixed] = useState(true)
  const [filterFake, setFilterFake] = useState(true)
  const [lotteLogisticsInvoiceNumbers, setLotteLogisticsInvoiceNumbers] =
    useState<string>("")
  const [
    processedLotteLogisticsInvoiceNumbers,
    setProcessedLotteLogisticsInvoiceNumbers,
  ] = useState<
    {
      invoiceNumber: string
      success: boolean
      errorMessage?: string
    }[]
  >([])

  const [searchInputBase, setSearchInputBase] = useState("")
  const [searchInput, setSearchInput] = useState(searchInputBase)
  const searchInputDelayRef = useRef(false)
  useEffect(() => {
    const q = searchInputBase
    ;(async () => {
      if (searchInputDelayRef.current) {
        return
      }
      searchInputDelayRef.current = true
      await sleepAsync(500)
      setSearchInput(q)
      searchInputDelayRef.current = false
    })()
  }, [searchInputBase])

  const clientIdInitializedRef = useRef(false)
  const [clientIdInitialized, setClientIdInitialized] = useState(false)
  const [selectedClientValue, setSelectedClientValue] = useState<Value>([])
  useEffect(() => {
    try {
      const clientId = router.query.clientId as string | undefined
      if (selectedClientValue.length > 0) {
        if (clientId && clientId === selectedClientValue[0].id) {
          return
        } else {
          router.push({
            pathname: router.pathname,
            query: {
              ...router.query,
              clientId: selectedClientValue[0].id,
            },
          })
        }
      } else if (clientId && !clientIdInitializedRef.current) {
        const value = targetClientIdValues.find(
          (value) => value.id === clientId
        )
        setSelectedClientValue(value ? [value] : [])
      }
    } finally {
      clientIdInitializedRef.current = true
      setClientIdInitialized(true)
    }
  }, [router, selectedClientValue])

  const { data: ordersData, mutate } =
    usePagination<ListTemporaryOrdersResponse>(
      clientIdInitialized
        ? `/api/temporary-orders?fromDate=${startDateStr}&toDate=${endDateStr}&clientId=${(selectedClientValue.length ===
          0
            ? TARGET_CLIENT_IDS
            : selectedClientValue.map(({ id }) => id)
          ).join(",")}${isUnfixed ? "&isFixed=false" : ""}`
        : null,
      PAGE_SIZE,
      true
    )

  const [showLGLModal, setShowLGLModal] = useState(false)
  const [showUnregisteredLGLModal, setShowUnregisteredLGLModal] =
    useState(false)
  const [lglDate, setLGLDate] = useState(new Date())
  const { data: unregisteredLotteLogisticsInvoiceNumbers } = useSWR<string[]>(
    `/api/lotte-logistics-orders/unregistered-invoices?date=${dayjs(
      lglDate
    ).format("YYYY-MM-DD")}`
  )

  useEffect(() => {
    mutate()
  }, [startDate, endDate, isUnfixed])

  const temporaryOrders = useMemo(
    () =>
      ordersData
        ?.flatMap((resp) => resp.temporaryOrders)
        .filter((order) => {
          if (filterFake) {
            if (
              order.clientId === "108472190524" &&
              !order.fixedReceiver?.address
            ) {
              return false
            }
          }
          if (!searchInput) {
            return true
          }
          return (
            order.invoiceNumber.includes(searchInput) ||
            order.clientShippingId.includes(searchInput) ||
            order.clientOrderId.includes(searchInput) ||
            order.forwardingInfo?.invoiceNumber.includes(searchInput) ||
            order.receiver.name.includes(searchInput) ||
            order.receiver.phone.includes(searchInput)
          )
        }),
    [filterFake, ordersData, searchInput]
  )
  const isLoading = !temporaryOrders

  const createLotteLogisticsOrders = useCallback(async () => {
    const invoiceNumbers = lotteLogisticsInvoiceNumbers
      .split("\n")
      .map((invoiceNumber) => invoiceNumber.trim().replace(/-/g, ""))
    for (const invoiceNumber of invoiceNumbers) {
      try {
        await axios.post(`/api/lotte-logistics-orders`, {
          invoiceNumber,
        })
        setProcessedLotteLogisticsInvoiceNumbers((prev) => [
          ...prev,
          {
            invoiceNumber,
            success: true,
          },
        ])
      } catch (err: Error | any) {
        setProcessedLotteLogisticsInvoiceNumbers((prev) => [
          ...prev,
          {
            invoiceNumber,
            success: false,
            errorMessage:
              {
                E20208: "동일 주문 존재",
                E20901: "주소 정제 실패",
                E20902: "주소 정제 결과와 우편번호 불일치",
                E20500: "서비스 구역 활성화 상태 아님",
                E00400: "요청 페이로드 오류",
              }[err?.response?.data?.errorCode as string] ||
              err?.response?.data?.errorMessage,
          },
        ])
        toaster.negative(
          `송장번호 ${invoiceNumber}를 등록하는데 실패했습니다.: ${err?.response?.data?.errorMessage}`
        )
      }
    }
    toaster.positive(
      `등록 완료 (성공 : ${
        processedLotteLogisticsInvoiceNumbers.length
      }개, 실패 : ${
        invoiceNumbers.length - processedLotteLogisticsInvoiceNumbers.length
      }개)`
    )
  }, [
    lotteLogisticsInvoiceNumbers,
    processedLotteLogisticsInvoiceNumbers.length,
  ])

  return (
    <div className="flex h-full flex-col">
      <div className="flex items-center gap-x-2 p-2">
        <div className="w-64">
          <DatePicker
            range
            locale={ko}
            value={[startDate, endDate]}
            onChange={({ date }) => {
              const [d1, d2] = date as Date[]
              setStartDate(d1)
              setEndDate(d2)
            }}
            monthsShown={2}
          />
        </div>
        <Badge content={`총 ${temporaryOrders?.length ?? "-"}개`} />
        <div className="w-full md:w-[500px]">
          <Input
            placeholder="송장번호 / 고객명 / 전화번호 / 고객사 주문, 운송번호 / 위탁사 송장번호"
            value={searchInputBase}
            onChange={(e) => setSearchInputBase(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setSearchInput(searchInputBase)
              }
            }}
          />
        </div>
        <div className="flex-1" />
        <Button
          size="compact"
          className="mx-0.5"
          onClick={() => {
            setShowUnregisteredLGLModal(true)
          }}
        >
          롯데택배 미등록 실패 송장 목록 조회
        </Button>
        <Button
          size="compact"
          className="mx-0.5"
          onClick={() => {
            setShowLGLModal(true)
          }}
        >
          롯데택배 미등록 송장 접수
        </Button>
      </div>
      <div className="flex items-center gap-x-2 p-2">
        <div className="flex w-96 flex-col">
          <FilterItem
            label="특정 고객사 선택"
            options={targetClientIdValues}
            value={selectedClientValue}
            setValue={setSelectedClientValue}
            radio
            labelPosition="left"
          />
        </div>
        <Checkbox
          checked={isUnfixed}
          onChange={(event) => setUnfixed(event.target.checked)}
        >
          <span className="ml-[-0.2rem] text-sm font-light">
            미해결 물품들만
          </span>
        </Checkbox>
        <Checkbox
          checked={filterFake}
          onChange={(event) => setFilterFake(event.target.checked)}
        >
          <span className="ml-[-0.2rem] text-sm font-light">
            허주문 의심 물품 제외
          </span>
        </Checkbox>
      </div>
      <div className="flex-1 overflow-y-scroll p-2 pb-0">
        <TemporaryOrderTable orders={temporaryOrders} isLoading={isLoading} />
      </div>
      <TemporaryOrderDetailDrawer
        key={router.query.orderId as string}
        orderId={router.query.orderId as string}
        onClose={() => {
          mutate()
          router.back()
        }}
      />
      <Modal
        isOpen={showLGLModal}
        onClose={() => {
          setLotteLogisticsInvoiceNumbers("")
          setProcessedLotteLogisticsInvoiceNumbers([])
          setShowLGLModal(false)
        }}
      >
        <ModalHeader>롯데택배 미등록 송장 접수</ModalHeader>
        <ModalBody>
          <FormControl label="송장 번호 리스트">
            <Textarea
              value={lotteLogisticsInvoiceNumbers}
              onChange={(event) =>
                setLotteLogisticsInvoiceNumbers(event.currentTarget.value)
              }
              placeholder="개행으로 구분된 송장 번호 목록을 입력해주세요."
            />
          </FormControl>
          <div>
            <h2>송장번호 목록</h2>
            <ul>
              {processedLotteLogisticsInvoiceNumbers.map((invoice) => (
                <li
                  key={invoice.invoiceNumber}
                  className={
                    invoice.success ? "text-green-600" : "text-red-600"
                  }
                >
                  {invoice.invoiceNumber} -{" "}
                  {invoice.success ? "성공" : `실패: ${invoice.errorMessage}`}
                </li>
              ))}
            </ul>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => createLotteLogisticsOrders()}>접수하기</Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={showUnregisteredLGLModal}
        onClose={() => {
          setShowUnregisteredLGLModal(false)
        }}
      >
        <ModalHeader>
          <img
            src={CryingPepe.src}
            width={30}
            height={30}
            className="inline-block"
          />
          롯데택배 미등록 등록 실패 송장 목록
          <img
            src={CryingPepe.src}
            width={30}
            height={30}
            className="inline-block"
          />
        </ModalHeader>
        <ModalBody>
          <div>
            <div className="w-64">
              {/* XXX: DatePicker가 autofocus되어 캘린더 팝업이 뜨는 것 임시 해결 */}
              <input className="h-0 w-0 opacity-0" />
              <DatePicker
                autoFocusCalendar={false}
                locale={ko}
                value={[lglDate]}
                onChange={({ date }) => {
                  setLGLDate(date as Date)
                }}
                placeholder="스캔 시도 날짜를 선택해주세요"
              />
            </div>
            <h2 className="mt-2">송장번호 목록</h2>
            {unregisteredLotteLogisticsInvoiceNumbers?.length === 0 && (
              <div>주소 정제에 실패한 물품이 없습니다</div>
            )}
            {unregisteredLotteLogisticsInvoiceNumbers?.length !== 0 && (
              <>
                <ul>
                  {unregisteredLotteLogisticsInvoiceNumbers?.map(
                    (invoiceNumber) => (
                      <li key={invoiceNumber}>{invoiceNumber}</li>
                    )
                  )}
                </ul>
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              const recipient = "yeonbum.jung@lotte.net"
              const cc = "dev@vtov.kr,operation@vtov.kr,seongvin.cho@lotte.net"
              const subject = `투데이 ${dayjs(lglDate).format(
                "YYYY-MM-DD"
              )} 발생 미등록 송장 등록 요창`
              const body = `
안녕하세요.

${dayjs(lglDate).format(
  "YYYY-MM-DD"
)}일에 발생한 미등록 송장 목록을 보내드립니다.

[송장번호 목록]
${unregisteredLotteLogisticsInvoiceNumbers?.join("\n")}

접수 부탁드립니다.

감사합니다.`

              window.location.href = `mailto:${recipient}?cc=${cc}&subject=${encodeURIComponent(
                subject
              )}&body=${encodeURIComponent(body)}`
            }}
          >
            메일 보내기
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
