import { LoadWithOrderState } from "@today/api"
import { Load } from "@today/api/tracker"

const verboseOrganizations: { [s in string]: string } = {
  CJ대한통운: "CJ",
  롯데택배: "롯데",
  HANJIN: "한진",
  stclogis: "STC",
}

function getVerboseOrganizationName(name: string) {
  return verboseOrganizations[name] ?? name
}

export function getLoadRepresentativeInvoiceNumber(
  load: Load | LoadWithOrderState
) {
  if (load.forwardingInfo?.invoiceNumber) {
    return {
      invoiceNumber: load.forwardingInfo.invoiceNumber,
      organization: getVerboseOrganizationName(
        load.forwardingInfo.deliveryOrganizationName
      ),
    }
  }
  if (
    ["CJ대한통운", "롯데택배", "한진", "stclogis"].includes(load.clientName)
  ) {
    return {
      invoiceNumber: load.clientShippingId || load.invoiceNumber,
      organization: getVerboseOrganizationName(load.clientName),
    }
  }
  // 익일택배 우진 물품은 실제로 판토스 송장번호이다. 우진 물품의 sender_name = 판토스로 접수된다.
  if (load.clientName === "우진" && load.sender.name === "판토스") {
    return {
      invoiceNumber: load.clientShippingId || load.invoiceNumber,
      organization: getVerboseOrganizationName(load.sender.name),
    }
  }
  return {
    invoiceNumber: load.invoiceNumber,
  }
}
